[
  {
    "id": "1",
    "name": "BigTime",
    "description": "Humanity is on the brink of extinction. The walls of spacetime are collapsing, different eras of history are colliding together, and forces of darkness are coming through the tears in time. You play as a time traveler who’s been summoned to the end of the universe, to take part in the greatest adventure in history.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmbt6cjrAVBAWpD1cta7QdcsuFGVkVxF9cM2vXVwEevN7C",
    "url": "https://www.bigtime.gg/"
  },
  {
    "id": "2",
    "name": "Planet IX",
    "description": "Planet IX is the biggest GameFi platform on the entire Polygon blockchain. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmNXW8U3eRCUcs8QyffL6S3g3c7oh8JsgTWawZ55KSVHR8",
    "url": "https://planetix.com/"
  },
  {
    "id": "3",
    "name": "Sorare",
    "description": "Sorare helps you experience fantasy sports through a virtual game. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qme8f4LuLetzEjSrv4KuvGrWv2Vn9LJMq2uE3z5adWRMoF",
    "url": "https://sorare.com/"
  },
  {
    "id": "4",
    "name": "Illuvium",
    "description": "Welcome to Illuvium, an enthralling series of fully decentralised RPG and collection games set in a fragmented world of beauty and wonder.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmds5HPHL2HEK1owJnx1oWJvRo5Hvkpt2StCsbDuorLWer",
    "url": "https://illuvium.io/"
  },
  {
    "id": "5",
    "name": "The Sandbox",
    "description": "The Sandbox, a popular 3D metaverse play-to-earn, allows users to create and monetize NFTs. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmXXjfTRD5DoT6Y4Mf6yGfLpytUm6yxaU92y4LxKrfEztD",
    "url": "https://www.sandbox.game/en/"
  },
  {
    "id": "5",
    "name": "World Shards",
    "description": "Sandbox MMORPG for endless exploration",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qme7g35t2D7sRhBEgVJXB11q85uFzK3wtfc2QiczyyfGAa",
    "url": "https://www.worldshards.online/"
  },
  {
    "id": "6",
    "name": "BF",
    "description": "Dive into an exhilarating gaming universe where VR Bosses and PC Fighters clash in futuristic battles for glory, fame, and real rewards!",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmNcDfGCwvo182yUVAPLs9q8zc45zNMPqTSrTLKrmcSNEk",
    "url": "https://bossfighters.game/"
  },
  {
    "id": "7",
    "name": "The Desolation",
    "description": "The Desolation brings a complete cross platform co-op looter shooter experience to PC and mobile devices. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmXa61BpfuGj62542STmk8GMtn6tbzaiC6nURKDck2wTpe",
    "url": "https://www.thedesolation-online.com/en/"
  },
  {
    "id": "8",
    "name": "Realms of Alurya",
    "description": "Synergy Land is an Action RPG game that integrates MOBA mechanics into a player-driven world that allows players real ownership of digital game assets, set in a fantasy world divided into four ecosystems: earth, water, fire and ice.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmdUwQLFg1NBdACUaSq9ZpwQrRgdjezir34DXRpbp4SuBp",
    "url": "https://www.synergyland.live/"
  },
  {
    "id": "9",
    "name": "Axie Infinity",
    "description": "Inspired by Nintendo's Pokémon series, Axie Infinity is a blockchain-based monster battling game. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmWbW4cLBzkuJPzi8iTPQsXsw5fXaeFcbxWRP8tzShYDnU",
    "url": "https://axieinfinity.com/"
  },
  {
    "id": "10",
    "name": "Decentraland",
    "description": "Decentraland is a virtual world built on Ethereum where users can create and monetize content. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmTGKgKxitFfpVJVfcTMyrMegBXRiHf9sBdYoGVAbS3kaz",
    "url": "https://decentraland.org/"
  },
  {
    "id": "11",
    "name": "Aurory",
    "description": "Aurory is a play-to-earn Japanese role playing game built on Solana.  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmRTV2fan5j7WtXKf5n6AvHqoTawamKniWyqQLLo4gtefE",
    "url": "https://aurory.io/"
  },
  {
    "id": "12",
    "name": "Gods Unchained",
    "description": "https://godsunchained.com/ ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmf9AVdtxPu74W9puL63eQgCHEZFudXx6gSzAQECzyxJTC",
    "url": "https://godsunchained.com/"
  },
  {
    "id": "13",
    "name": "Parallel",
    "description": "Parallel is a sci-fi based competitive card game where players build decks and face off, bank energy, and build armies to decide the fate of humanity",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmeR2D8wLN7XdT1YxoF3TH3yHqzeEQPopQ5YEoZubitYzx",
    "url": "https://parallel.life/"
  },
  {
    "id": "14",
    "name": "Habbo X",
    "description": "Habbo X is an upcoming game from the creators of Habbo Hotel that  focuses on community building, interoperability, and play-and-earn  mechanics.  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmeT3HELWpMiz5JPEKFcRQFMNjeVdJKnWFxvdNLGA6jjxN",
    "url": "https://www.habbox.game/"
  },
  {
    "id": "15",
    "name": "Pixels",
    "description": "Make your home in a world of unlimited adventure. Master skills and play with friends. Build new communities. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmVow7zsmu3XQgN7g2jPfihVyBdQzfQRq4xiMxKomHpCN7",
    "url": "https://www.pixels.xyz/"
  },
  {
    "id": "16",
    "name": "Guild of Guardians",
    "description": "Guild of Guardians is a web3 mobile roguelite RPG where players summon guardians, join a guild, and defend Elderym from the Dread.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmRJ4k5Au2xVXGJ95GTHhu7gHgJ3t5di6HGDDieXCwncAp",
    "url": "https://www.guildofguardians.com/"
  },
  {
    "id": "17",
    "name": "Mirandus",
    "description": "Mirandus is a fantasy world powered by blockchain technology, allowing players to truly create their own content.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmYJKAoQaq1ZNag7CyDKQcCeDmDbD5w7T1SEQRnn1YG37e",
    "url": "https://mirandus.game/"
  },
  {
    "id": "18",
    "name": "Common Ground World",
    "description": "Built by the pioneers of farm sims who brought the world Farmville, Common Ground World is a delightful casual farm game with the power to change the world.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmRuv6KqDwik7TB4HMqbnpynKzy53H2rp7GeUFStvouCdB",
    "url": "https://commonground.game/"
  },
  {
    "id": "19",
    "name": "One Tap",
    "description": "#1 web3 Arena Shooter. Your asset choices, your strategic edge. Play smarter. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmYdHgWgJDFmMBYYMe1hYWMonTdyjiy6Qtmf97iuaaAaNJ",
    "url": "https://playonetap.com/"
  },
  {
    "id": "20",
    "name": "Star Atlas",
    "description": "A grand strategy game of space exploration, territorial conquest, political domination,and more.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmaPdQkBXLC6ZnNWQ741RAW36EPooi2nExnZ9coWtA3BDM",
    "url": "https://staratlas.com/#"
  },
  {
    "id": "21",
    "name": "Splinterland",
    "description": "Splinterlands is a collectible trading multiplayer card game with design that will make you think of a symbiosis between pokemon card games and World of Warcraft characters. The game has a developed storytelling based on a mystical world. Built on the Steem Blockchain to offer fast transactions and full transparency. Get in the game, conquer a world or solve quests. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmPZkxWpWXJEZFDUobdbuKb1JZJcG6omhtY6oVRnwRPtDP",
    "url": "https://splinterlands.com/"
  },
  {
    "id": "22",
    "name": "Cornucopias",
    "description": "Cornucopias is a cutting edge open world MMO set in a world where humanity has ascended from the Earth's surface and created a breathtaking new world in the sky. Be immersed in vast landscapes, explore vibrant settlements, and engage in a myriad of activities such as crafting, commerce, combat, racing, and more. Welcome to a world where the sky is no longer the limit.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmQeWFCt4BV76SXMhiSDaCVN1LvePfrXGVBNgDFhFCU6Nh",
    "url": "https://cornucopias.io/"
  },
  {
    "id": "23",
    "name": "Pirate Nation",
    "description": "Join a new type of blockchain game on the high seas. Build your ship, recruit your crew, and battle your way across the isles to become the top pirate! What say ye, matey?",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmRnPGoHEhRu68Zv9jdBR2cr8xU4DC9E4TtxqwJB4p1S3L",
    "url": "https://piratenation.game/?utm_source=DappRadar&utm_medium=deeplink&utm_campaign=visit-website"
  }
]
