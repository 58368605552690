import React from "react";
import classes from "./Arbitration.module.css";

const Arbitration = () => {
  return (
    <div className={classes.mainContainer}>
      <h1 className={classes.title}>Coming Soon.</h1>
    </div>
  );
};

export default Arbitration;
