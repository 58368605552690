.mainContainer {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #242529;
}
.title {
  font-family: "Helvetica";
  font-size: 30px;
}
