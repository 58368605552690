.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.title {
  font-size: clamp(1.5rem, 3vw, 4rem);
  font-weight: 600;
  font-family: "Lato";
  color: white;
}
