.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardContainer {
  color: rgb(189, 189, 189);
  display: flex;
  align-items: center;
  max-width: 350px;
  height: 150px;
  margin: 10px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #111111, #222222);
  border-style: solid;
  border: solid 2px lightblue;
}
.cardContainer:hover {
  border: solid 2px gold;
  scale: 1.05;
}

.openGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 340px;
  max-height: 145px;
  border-radius: 18px;
}

.chainId {
  margin-left: 5px;
  color: white;
  font-size: 24px;
  font-family: "Lato";
}

.description {
  margin-top: 5px;
  color: white;
  font-size: 12px;
  font-family: "Lato";
}

.tokenAddress {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
  font-size: 14px;
  font-family: "Lato";
  word-wrap: break-word;
}

/*background-image: linear-gradient(to right, #111111, #222222);*/
