.formContainer {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formSection {
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
}
.sectionBreak {
  height: 150px;
  width: 100%;
  border-bottom: solid 1px black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 14px;
}
.formSectionMessage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input {
  width: 98%;
  height: 35px;
  display: flex;
  justify-content: center;
  padding-left: 2%;
  border-radius: 20px;
  border: solid 1px black;
  font-family: "Lato";
  font-size: 16px;
}
.inputText {
  height: 20px;
  font-size: 20px;
  font-size: "Lato";
}
.message {
  width: 98%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 20px;
  border: solid 1px black;
  font-family: "Lato";
  font-size: 16px;
}

.submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 60px;
  width: 120px;
  color: #ffffff;
  font-family: "Familjen Grotesk", Helvetica;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border: 1px solid #93012f;
  border-radius: 15px;
}
.submitBtn:hover {
  cursor: pointer;
  border: 1px solid #ffffff;
  scale: 1.05;
}
.messageRes {
  font-size: 20px;
  margin-bottom: 10px;
}
