.mainContainer {
  color: white;
  width: 50%;
  height: 90%;
  margin-left: 25%;
  padding: 5%;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 48px;
  font-family: "Lato";
  margin-bottom: 10px;
}
.text {
  font-size: 20px;
}

.header {
  padding: 3%;
  margin-bottom: 50px;
}
