[
  {
    "id": "1",
    "name": "Binance",
    "description": "One of the world’s largest cryptocurrency exchanges by trading volume, offering a wide range of services including spot, futures, and staking options. ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmP2NLdGYss6p9F8eA72hbyYXzb3hMzRouPXjJka77fiyz",
    "url": "https://www.binance.com/"
  },
  {
    "id": "2",
    "name": "Bitget",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmTzxKLFvqaUg7JjSkVD9ecuxfHr3HiwiJL68rxHhbMaGs",
    "url": "https://www.bitget.com/"
  },
  {
    "id": "3",
    "name": "BingX",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmNSod4QVcxFwf2xnbSzQegjg7u6FDyUypFV7aFqpK59HP",
    "url": "https://bingx.com/en/"
  },
  {
    "id": "4",
    "name": "Digifinex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qme1XKxnzSmgyiuc7Eg6nYZ16qED4QVpoeYkkTbf6sSrpH",
    "url": "https://www.digifinex.com/en-ww"
  },
  {
    "id": "5",
    "name": "BitMart",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmbECG9RvJ42C8c3cLTtY9bRrvqJXi1QdjntnMU63wdQNw",
    "url": "https://www.bitmart.com/en-US"
  },
  {
    "id": "6",
    "name": "Coinbase",
    "description": "The future of money is here. We're the most trusted place for people and businesses to buy, sell, and use crypto.",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmQCypKWVaPWitUUsvpu8GHf53CYMqog3KRVCHDdqRvRuw",
    "url": "https://www.coinbase.com/"
  },
  {
    "id": "7",
    "name": "Bitmex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmckn7tF8a6REZbMGbWX9wMtN4gEzDj9qDVjDbAanXnkhn",
    "url": "https://www.bitmex.com/"
  },
  {
    "id": "8",
    "name": "Okex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmNyEzZX3rPei1PCa2x25iFasVhPdBUzYxbhqFWN2zcw31",
    "url": "https://www.okx.com/"
  },
  {
    "id": "9",
    "name": "Kucoin",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmZzbMZDShAR9HzVENeQyQ1dxwoGz5KLt2rAV8Zezoj1Ti",
    "url": "https://www.kucoin.com/"
  },
  {
    "id": "10",
    "name": "Kraken",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmeohoLRPdDLDszJeJzXFZpzeRcz35FXb2Y4eXJsii5fW4",
    "url": "https://www.kraken.com/lp/journey?utm_source=google&utm_medium=cpc&utm_campaign=2024_q1_blended_us_brand_acq_per_id:21140669680&utm_content=163349089347&utm_term=kraken&gad_source=1&gclid=CjwKCAiAmfq6BhAsEiwAX1jsZzHFOGfeScFkVPOdyB_32GAGyJ4Kda3rRnMjtUe4A2lEnHr_nsaKsRoCTAoQAvD_BwE"
  },
  {
    "id": "11",
    "name": "LBank",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmTyZscjQH9J98yJvSzFCWpV8hB7xpddqSUDToYxjME6mu",
    "url": "https://www.lbank.com/"
  },
  {
    "id": "12",
    "name": "Huobi Global",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmeK2oLzmqKxhh7fiJVj8zH5qWrLa9apKyZQNZATEqRjbj",
    "url": "https://www.htx.com/"
  },
  {
    "id": "13",
    "name": "BitStamp",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmWbcKowMtrv2XhAcJ4Fp4CfGxrKszppDwtUhzMYkozD2G",
    "url": "https://www.bitstamp.net/"
  },
  {
    "id": "14",
    "name": "Bitfinex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmPQqcJWPMN8EDQUcuXAYNU7APDHhsz7QAbDrh6Vyxz82t",
    "url": "https://www.bitfinex.com/"
  },
  {
    "id": "15",
    "name": "Poloniex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmeHLoZtScByzHYtWvPRJLgoayCS8W5QqqaGuV8v9CVxz6",
    "url": "https://poloniex.com/"
  },
  {
    "id": "16",
    "name": "CoinEx",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmdtoYzjzNGMsCFzdcNsGHVnxKaA5E5RrhQySgnCbF6j14",
    "url": "https://www.coinex.com/en/"
  },
  {
    "id": "17",
    "name": "Gate io",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmQ13RG2DSe2FkiuiBffkhyNAZMiSV92ard7pt8nwVjkXH",
    "url": "https://www.gate.io/"
  },
  {
    "id": "18",
    "name": "bitFlyer",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmRv9Nh2GYUJWy4Wa2QHUD6m42u4QnfNrn49uowCxz6mou",
    "url": "https://bitflyer.com/en-us/"
  },
  {
    "id": "19",
    "name": "Bitbank",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmQgC1RnUkZJjjGE7ZhmrUrcgTFXukZF5agUr4qjTCgLZZ",
    "url": "https://bitbank.cc/en/"
  },
  {
    "id": "20",
    "name": "BtcTurk",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmNyfX74v4LufbRPmvFgqGceXoG67vfPTPCGwiom8LS1dj",
    "url": "https://www.btcturk.com/"
  },
  {
    "id": "21",
    "name": "HitBTC",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmYMt6bp44oEnoj7r5xa9N4asVQwQwkayEqwyBLFAUqvLj",
    "url": "https://hitbtc.com/"
  },
  {
    "id": "22",
    "name": "Bibox",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmbSh2aosRSGoqiciG1yN4jiwHH5mfP5zptKqQoH3RZUbZ",
    "url": "https://www.bibox.win/en"
  },
  {
    "id": "23",
    "name": "Gemini",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/QmaG8uqTjJSqoqRoZS4JK6m25v7kPjDWcjgT9AVBXo9wUC",
    "url": "https://www.gemini.com/"
  },
  {
    "id": "24",
    "name": "Phemex",
    "description": "Join the largest copy trading platform today  ",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmc3cPL7fAzK5NYghJ8mf55VpnNUdpCCgEMbVu76rd7WMS",
    "url": "https://phemex.com/"
  },
  {
    "id": "25",
    "name": "Bybit",
    "description": "Embark on Your Crypto Journey Today!",
    "logo": "https://physical-chocolate-marmoset.myfilebase.com/ipfs/Qmabz73nQpWuuSyKbcSqkFbnh1SQ9LRJtVcZmJt3ArS9WZ",
    "url": "https://www.bybit.com/en"
  }
]
